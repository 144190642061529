<template>
  <div class="position-fixed">
    <div v-if="$inscricaoAberta" class="w-100 text-end">
      <button data-toggle="modal" data-target="#modalLogin" id="modalLoginClose" class="btn btn-primary mr-3 mt-3">
        Área do candidato
      </button>
    </div>

    <div class="modal fade" id="modalLogin" tabindex="-1" role="dialog" aria-labelledby="modalLoginLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-16 mb-0">
              <span @click="openInfos" class="pointer pr-1">
                <i class="fa-solid font-18 fa-circle-info" />
              </span>
              Área do Candidato
            </h5>
            <button id="close-modal-login" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="logar">
              <div class="row justify-content-center">
                <div class="col-12 mb-3">
                  <FieldInput
                    type="email"
                    label="Email"
                    :required="true"
                    v-model="form.email"
                    placeholder="Informe o email cadastrado aqui:"
                  />
                </div>

                <div class="col-12">
                  <FieldInput
                    label="Senha"
                    :type="showPassword ? 'text' : 'password'"
                    :required="true"
                    v-model="form.senha"
                    placeholder="Informe aqui sua senha:"
                  >
                    <div
                      class="pointer input-group-apend bg-primary d-flex justify-content-center align-items-center px-2"
                      @mousedown="showPassword = true"
                      @mouseup="showPassword = false"
                    >
                      <i :class="['fa-regular text-white font-14', !showPassword ? 'fa-eye' : 'fa-eye-slash']" />
                    </div>
                  </FieldInput>
                </div>
              </div>

              <div class="text-center mt-4 pt-3">
                <button :disabled="!isEnabled" type="submit" class="btn btn-primary py-1">Entrar</button>
              </div>
              <div class="text-center mt-3">
                <p class="recover" @click="recuperarSenha">Recuperar Senha</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import FieldInput from '@/components/fields/FieldInput'

export default {
  name: 'LoginComp',

  components: {
    FieldInput
  },

  data() {
    return {
      form: {
        email: '',
        senha: '',
        id: false
      },
      showPassword: false,
      hasPassword: false,
      infos: {}
    }
  },

  computed: {
    isEnabled() {
      return this.form.email && this.form.senha
    }
  },

  methods: {
    async recuperarSenha() {
      if (!this.form.email) return

      const { value } = await this.$sweet.sweetQuestionPersonalizado(
        'Recuperar senha',
        '<p class="font-15">Realmente deseja recuperar sua senha? Ao clicar em confirmar, uma nova senha será enviada para o seu email.</p>',
        'Confirmar'
      )
      if (!value) return

      this.$sweet.sweetWait('Recuperando senha.')
      const { data } = await axios
        .get(`/formulario/${this.$city}/${this.$psCity}/validar-inscricao-email/${this.form.email}`)
        .catch((e) => e.response)
      if (isNaN(data)) return this.$sweet.sweetMensagem(data)

      const { status } = await axios.get(`/formulario/${this.$city}/${this.$psCity}/recuperar-senha/${this.form.email}`).catch(() => {
        return { status: 500 }
      })

      if (status >= 300) return this.$sweet.sweetNotification('Houve um erro ao enviar a senha..')
      this.$sweet.sweetSuccess('A senha foi enviada para o seu email.')
    },

    openInfos() {
      this.$sweet.sweetMensagem(
        `
        <p class="font-13 mt-3 text-left text-modal">- A senha provisória para acesso à área do candidato foi enviado ao e-mail cadastrado, caso não tenha recebido em sua caixa de entrada, verifique a caixa de SPAM / Lixo Eletrônico e certifique-se de que há espaço para receber mensagens.</p>
        <p class="font-13 mt-3 text-left text-modal">- Caso tenha deletado o e-mail com a senha provisória ou esqueceu a senha escolhida, faça o processo de recuperação de senha clicando em “RECUPERAR SENHA”, uma nova senha será enviada para o e-mail cadastrado.</p>
        <p class="font-13 mt-3 text-left text-modal">- Caso não receba o e-mail de recuperação de senha, solicite suporte enviando uma mensagem para o endereço <a class="text-success" href="mailto:suporte@ibcgp.org.br">suporte@ibcgp.org.br</a>, indicando no campo assunto “PSV – EDITAL N. 02/2024 - SENHA DE ACESSO” ou através do whatsapp <a class="text-success" href="tel:+6730424800">(67) 3042-4800</a>.</p>
      `,
        'info'
      )
    },

    async logar() {
      this.$sweet.sweetWait('Estamos validando seu acesso.')

      const params = this.form
      const { data: respToken, status: statusToken } = await axios
        .get(`/formulario/${this.$city}/${this.$psCity}/area-candidato/login`, {
          params
        })
        .catch((e) => {
          return { status: 500, data: e.response?.data }
        })

      if (statusToken > 299) return this.$sweet.sweetNotification(respToken)
      if (!respToken) return this.$sweet.sweetNotification('Email inválido.')

      axios.defaults.headers['Authorization'] = `Bearer ${respToken.token}`
      localStorage.setItem(`token-${this.$city}`, respToken.token)

      const { data, status } = await axios.get(`/formulario/${this.$city}/${this.$psCity}/area-candidato/dados`).catch((e) => {
        return { status: 500, data: e.response?.data }
      })

      if (status > 299) return this.$sweet.sweetNotification(data)

      const [[{ inscricao }]] = data

      this.$sweet.sweetClose()
      document.getElementById('close-modal-login').click()
      this.$store.dispatch('profile/setProfile', { ...this.form, ...inscricao })

      this.$router.push({ name: 'area-form' })
    }
  }
}
</script>

<style lang="sass">
.limiter
  background-color: #f2f2f2
.input100
  border-radius: 15px !important
.login100-form
  width: 50% !important
.login
  top: 0px
  right: 0px
.floatbtn
  right: 20px
  top: 20px
.login100-form-btn
  background-color:  #469338 !important
  border-radius: 15px !important
.login100-form-title
  padding-bottom: 30px !important
.wrap-login100
  box-shadow: 1px 1px 10px 3px rgba(45,45,100,.25)
  background-color: #fbfbfb
  padding: 100px !important
.cursor-disabled
  cursor: no-drop !important
  opacity: .5
@media (max-width: 500px)
  .login100-form
    width: 100% !important
  .wrap-login100
    padding: 50px 25px !important
.position-fixed
  position: absolute
  right: 15px
.recover
  cursor: pointer
  color: green !important
  text-align: center
  font-size: 13px
.input-group-apend
  border-top-right-radius: 4px !important
  border-bottom-right-radius: 4px !important
.weight-500
  font-weight: 500 !important
</style>
