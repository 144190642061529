<template>
  <div id="app">
    <Login :inscricaoAberta="$inscricaoAberta" />
    <ValidarInscricao :inscricaoAberta="$inscricaoAberta" />
    <Footer />
  </div>
</template>

<script>
import Login from '@/components/Login'
import Footer from '@/components/Footer'
import ValidarInscricao from '@/components/ValidarInscricao'

export default {
  name: 'HomeComp',
  components: {
    Login,
    Footer,
    ValidarInscricao
  },
  // created() {
  //   this.$sweet.sweetSuccess(
  //     `
  //       <h5 class="mb-3">Inscrição realizada com sucesso</h5>

  //       <div class="bg-light py-3 px-4 mt-3">
  //         <h6 class="text-success mb-3">Atenção!</h6>
  //         <p class="text-modal text-center">Uma senha provisória foi enviada ao e-mail cadastrado em sua ficha de isncrição para acesso à área do candidato onde serão disponibilizados a ficha de inscrição, links e informações. <br><br> Caso não receba o e-mail com a senha provisória imediatamente após a inscrição, verifique a caixa de spam e se seu e-mail não está sem espaço.</p>
  //       </div>
  //     `,
  //     100000
  //   )
  // },

  data() {
    return {
      inscricaoAberta: true,
      page: 'login',
      login: { email: '', form: 'login', cpf: '' }
    }
  }
}
</script>
<style lang="sass" scoped>
#app
  position: relative
</style>
