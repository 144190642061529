<template>
  <section id="section-print">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :filename="`Comprovante de inscrição nº ${formulario.id || ''}`"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <div slot="pdf-content">
        <div class="px-5 pt-2">
          <div class="row justify-content-center">
            <div class="col-12 pb-1">
              <div class="row justify-content-bettwen">
                <div class="col-12 align-self-end text-center mb-3">
                  <img class="img-brasao" src="../assets/images/brasao.jpeg" />
                </div>
                <div class="col-12 align-self-end">
                  <h5 class="text-center font-17">PREFEITURA MUNICIPAL DE VICENTINA</h5>
                </div>
                <div class="col-12 align-self-end">
                  <p class="text-center font-13">
                    PROCESSO SELETIVO SIMPLIFICADO PARA FORMAÇÃO DE CADASTRO DE RESERVA PARA CONTRATAÇÃO TEMPORÁRIA DE PROFESSORES E OUTROS
                    CARGOS DA REDE PÚBLICA MUNICIPAL DE EDUCAÇÃO DO MUNICÍPIO DE VICENTINA - ESTADO DE MATO GROSSO DO SUL
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 mb-4">
              <h4 class="text-center" style="font-size: 15px">PROTOCOLO DE INSCRIÇÃO</h4>
            </div>

            <div class="col-4">
              <p><span>Número:</span> {{ mountNumInsc(formulario.id) || '--' }}</p>
            </div>
            <div class="col-8">
              <p>
                <span>Data de inscrição:</span>
                {{ mountDateTime(formulario.createdAt || formulario.created_at) }}
              </p>
            </div>

            <div :class="['col-md-12 py-1', form.class]" v-for="(form, ind) in forms" :key="`${ind}-form`">
              <p class="mb-2 bg-light px-2 d-flex align-items-center">
                <i :class="[form.icon, 'mr-1']"></i>
                {{ form.titulo }}
              </p>

              <div class="row">
                <div
                  :key="`${indInput}-input`"
                  v-show="!input.hidePrint"
                  :class="[input.getclass || input.class]"
                  v-for="(input, indInput) in form.inputs"
                >
                  <p v-if="input.conditional ? input.conditional(formulario) : true">
                    <span class="bold">{{ input.label }}</span> {{ getValue(input) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-html2pdf>
  </section>
</template>

<script>
import camelize from 'camelize'
import VueHtml2pdf from 'vue-html2pdf'
import { sections as forms } from '../views/formInputs'

export default {
  name: 'ImpressaoComp',

  components: {
    VueHtml2pdf
  },

  props: {
    formulario: {
      type: Object
    },
    isHomePage: {
      type: Boolean
    }
  },

  data() {
    return {
      forms
    }
  },

  methods: {
    shouldShow(key) {
      return key !== 'repeatcpf'
    },

    getOptions(input, formulario) {
      if (typeof input.options === 'function') return input?.options(formulario, input.key) || []
      return input.options
    },
    mountPhone(phone) {
      const count = phone?.length
      if (!phone) return '--'

      const toSlice = count === 11 ? 7 : 6
      const ddd = phone.slice(0, 2)
      const first = phone.slice(2, toSlice)
      const second = phone.slice(toSlice, phone.length)
      return `(${ddd}) ${first}-${second}`
    },

    getValue(input) {
      const key = this.isHomePage ? input.key : input.getkey || input.key
      const val = this.formulario?.[key || camelize(key)] || '--'
      if (input.options) {
        const isArray = Array.isArray(input.options)
        if (isArray) return input.options.find((i) => i.value.toLowerCase() === val.toLowerCase())?.label
      }
      if (input.getconvert && !this.isHomePage) return input.getconvert(val)

      return val
    },

    generatePdf() {
      this.$refs.html2Pdf.generatePdf()
    },

    yesOrNo(resp) {
      return resp === 'S' ? 'Sim' : 'Não'
    },

    mountDate(date) {
      return this.$f.invertData(date)
    },

    mountDateTime(date) {
      return this.$f.mountDate(date)
    },

    mountNumInsc(num) {
      return `${num}`?.padStart(6, '0')
    }
  }
}
</script>
<style lang="sass" scoped>
br
  margin-bottom: 10px
.border-bottom
  border-bottom: 1px solid black
p,h1,h2,h3,h4,h5,h6
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important
p
  font-size: 13px
  color: #000 !important
  font-weight: 300 !important
  span
    font-size: 14px
    font-weight: 600 !important
.position-absolute
  position: absolute
.img-brasao
  width: 80px
.img-imp
  width: 50px
.vue-html2pdf
  .layout-container
    background-color: #fff !important

  .content-wrapper
    background-color: #fff !important
.bg-light
  padding-top: 0px
  padding-bottom: 0px
  i
    font-size: 13px !important
.font-17
  font-size: 17px !important
.font-13
  font-size: 12px !important
</style>
