<template>
  <div>
    <label :for="id" v-if="label" class="label" v-html="`${label} ${required ? '*' : ''}`"></label>

    <SelectComp
      :selectable="(option) => !option.disabled"
      :id="id"
      :disabled="isDisabled"
      :options="options || []"
      v-model="field"
      placeholder="Selecione uma opção"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    id: {},
    label: {},
    options: {},
    required: {},
    isDisabled: {}
  },

  data() {
    return {
      field: ''
    }
  },

  mounted() {
    this.field = this.options.find((item) => item?.value === this.value || '')
  },

  watch: {
    value: {
      handler(value) {
        if (this.$f.isEqual(value, this.field)) return
        this.field = this.options.find((item) => item?.value === value)
      }
    },

    field: {
      handler(field) {
        if (this.$f.isEqual(field, this.value)) return
        this.$emit('input', field?.value || '')
      }
    }
  }
}
</script>
<style>
.vs__selected-options {
  box-sizing: border-box;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;

  text-overflow: ellipsis;
}
.vs__search {
  box-sizing: border-box;
  max-height: 24px;
}
input::placeholder {
  color: #888 !important;
  font-weight: 400;
  font-size: 12px !important;
  opacity: 1;
}
</style>
