<template>
  <div class="validate-input mb-2">
    <input
      v-mask="mask"
      @blur="blur ? blur(field) : () => {}"
      :type="type"
      v-model="field"
      :id="id"
      :required="required"
      :disabled="isDisabled"
      :placeholder="placeholder"
      :class="['input100', isDisabled ? 'cursor-disabled' : '']"
    >
    <span class="focus-input100" />
    <span class="symbol-input100">
      <i :class="iconClass" />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    blur: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {
      field: this.value
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        if (!this.isBlur && isEqual(value, this.field)) return
        this.field = value
      }
    },

    field: {
      deep: true,
      handler (field) {
        if (!this.isBlur && isEqual(field, this.value)) return
        this.$emit('input', field)
      }
    }
  }
}

const isEqual = (val1, val2) => val1 === val2
</script>
