<template>
  <div class="area-titulo p-4">
    <p v-if="!isEnabled" class="text-danger mb-3 weight-300">Prazo para envio finalizado</p>
    <div class="mb-5" v-if="isEnabled">
      <form @submit.prevent="enviar">
        <div class="px-md-5">
          <div class="row justify-content-center bg-light py-3">
            <div class="col-12">
              <h5 class="mb-4">Formulário para recurso em face do gabarito preliminar</h5>
            </div>

            <div class="col-md-4 mb-3">
              <FieldInput
                mask="##"
                :min="1"
                :max="20"
                type="number"
                required
                label="N.º da questão recorrida"
                v-model="arquivo.numeroQuestao"
                placeholder="Informe aqui o N.º da questão recorrida"
              />
            </div>

            <div class="col-md-4 mb-3">
              <FieldFile upperLabel="Anexo" accept="application/pdf" key="arquivo" v-model="file" label="Tamanho máximo: 2MB" />
            </div>

            <div class="col-md-8 mb-3">
              <FieldInput
                type="textarea"
                required
                v-model="arquivo.motivo"
                placeholder="Informe aqui o fundamento do recurso"
                label="Justificativa do candidato - Fundamento do recurso"
              />
            </div>

            <div class="col-md-5">
              <button type="submit" class="btn btn-primary py-1 font-14">
                <i class="fa-solid fa-plus" />
                Adicionar Impugnação
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <h5 class="mb-2">Pedidos de Impugnação</h5>

    <img v-if="isLoading" src="@/assets/images/loading.gif" class="is-loading" />

    <p v-else-if="!list.length" class="text-secondary text-center weight-400">Nenhuma Impugnação enviada</p>

    <div v-else class="px-md-5">
      <div
        :key="item.title"
        v-for="(item, ind) in list"
        :class="['row justify-content-between align-items-center py-2', (ind + 1) % 2 && 'bg-light']"
      >
        <div class="col-md-2 col-4">
          <p class="font-13">
            <span class="pt-1 bold">{{ $f.mountDate(item.created_at) }}</span>
          </p>
        </div>

        <div class="col-md-2 col-4">
          <p class="font-13 text-start">Questão {{ item.questao }}</p>
        </div>

        <div class="col">
          <p class="font-13 text-start">
            {{ item.motivo }}
          </p>
        </div>

        <div class="col-12 col-md-auto text-center px-0 px-md-3 mt-2 mt-md-0">
          <a v-if="item.extensao_anexo" download target="_blank" :href="item.link" class="btn btn-primary py-1 font-13 text-white">
            <i class="fa-solid fa-download" />
            Download
          </a>

          <span
            v-if="item.status === 'P' && isEnabled"
            @click="excluir(item)"
            target="_blank"
            :href="item.link"
            class="btn btn-danger py-1 font-13 text-white pointer ml-2"
          >
            <i class="fa-solid fa-trash-can" />
            Excluir
          </span>
        </div>
      </div>
    </div>

    <!-- <div class="bg-light mx-md-5 mx-1 mt-5">
      <h5 class="py-3 mb-3 text-center">Informações de Impugnação</h5>

      <div class="px-5 pb-2">
        <ul>
          <li>Indicar no campo específico o número da questão, a resposta assinada pelo candidato e a resposta divulgada no gabarito;</li>
          <li>Indicar no campo específico a fundamentação da não concordância;</li>
          <li>Anexar em arquivo em formato PDF cópia da bibliografia que fundamente o recurso;</li>
          <li>O candidato terá que apresentar um recurso para cada questão;</li>
          <li class="bold">Atenção! Não será aceito recurso fora dos prazos indicados no Edital</li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import FieldFile from '@/components/fields/FieldFile'
import FieldInput from '@/components/fields/FieldInput'

export default {
  components: {
    FieldInput,
    FieldFile
  },

  data() {
    return {
      list: [],
      isLoading: false,
      isEnabled: false,
      arquivo: {
        numeroQuestao: 1,
        motivo: ''
      },
      file: {}
    }
  },

  created() {
    this.loadArchives()
  },

  methods: {
    async excluir(item) {
      const { value } = await this.$sweet.sweetQuestionPersonalizado(
        'Excluir',
        '<p class="font-15">Realmente deseja excluir esta impugnação?</p>',
        'Confirmar'
      )
      if (!value) return

      this.$sweet.sweetWait('Excluindo Impugnação.')
      const { error } = await axios
        .delete(`/formulario/${this.$city}/${this.$psCity}/area-candidato/impugnacao/prova/${item.id}`)
        .catch(() => ({ error: 'error' }))
      if (error) return this.$sweet.sweetMensagem('Erro ao remover o Impugnação. Tente novamente')
      this.$sweet.sweetSuccess('Impugnação excluída com sucesso.')
      this.loadArchives()
    },

    async loadArchives() {
      this.isLoading = true
      const { data } = await axios.get(`/formulario/${this.$city}/${this.$psCity}/area-candidato/impugnacao/prova`)
      this.isLoading = false
      this.list = (data || []).map((i) => ({
        ...i,
        link: `https://ibcgp-plataforma.sfo3.cdn.digitaloceanspaces.com/concurso-ps/${this.$city}/${this.$ps}/pedidos-impugnacao/${this.profile.id}/${i.id}.${i.extensao_anexo}`
      }))
    },

    async enviar() {
      this.$sweet.sweetWait('Enviando Impugnação.')

      const { data: idArchive } = await axios.post(
        `/formulario/${this.$city}/${this.$psCity}/area-candidato/impugnacao/prova`,
        this.arquivo
      )
      if (isNaN(idArchive)) return this.$sweet.sweetMensagem('Erro ao criar a Impugnação.')

      if (this.file?.type && this.file?.size) {
        const { data: ext } = await this.saveDoc(idArchive)
        if (!ext) return this.$sweet.sweetMensagem('Erro ao enviar o arquivo de Impugnação.')

        const { data: putExt } = await axios.put(
          `/formulario/${this.$city}/${this.$psCity}/area-candidato/impugnacao/prova/${idArchive}/extensao/${ext}`
        )
        if (!putExt) return this.$sweet.sweetMensagem('Erro ao atualizar a extensão da Impugnação.')
      }

      this.$sweet.sweetSuccess('Impugnação cadastrado com sucesso.')
      this.loadArchives()
    },

    async saveDoc(id) {
      const headers = { 'Content-Type': 'multipart/form-data' }
      const dataForm = new FormData()
      dataForm.append('arquivo', this.file)
      dataForm.append('diretorio', `concurso-ps/${this.$city}/${this.$ps}/pedidos-impugnacao/${this.profile.id}`)
      dataForm.append('identificador', id)
      return axios.post('/arquivo', dataForm, { headers }).catch((err) => err)
    }
  },

  computed: {
    ...mapGetters({ profile: 'profile/getProfile' })
  }
}
</script>

<style lang="sass" scoped>
@media (max-width: 500px)
  p
    line-height: 18px
    text-align: left
  a
    margin: auto
    height: 22px
    display: inline-flex
    align-items: center
    justify-content: center
.area-titulo
  background-color: #fff
  padding: 25px 15px
  border-radius: 5px
h5
  font-size: 18px
  text-align: center
  font-weight: 500 !important
.font-13
  font-size: 13px
.font-15
  font-size: 15px
.font-14
  font-size: 14px
.is-loading
  width: 30px
.weight-400
  font-weight: 400
.text-start
  text-align: left !important
</style>
