import swal from 'sweetalert2/dist/sweetalert2.all.min.js'

export default class sweetAlert {
  constructor() {
    this.swalWithBootstrapButtons = swal.mixin({
      allowOutsideClick: false,
      allowEscapeKey: false
    })
  }

  sweetNotification(msg) {
    swal.fire({
      title: '<strong>Ops, por favor confira os dados!</strong>',
      type: 'info',
      icon: 'info',
      showCloseButton: true,
      html: `<h5>${msg}</h5>`,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok'
    })
  }

  sweetClose() {
    swal.fire({
      showConfirmButton: false,
      timer: 1
    })
  }

  sweetMensagem(msg, icon = 'warning') {
    swal.fire({
      icon,
      type: 'info',
      showCloseButton: true,
      html: msg,
      color: '#fff',
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonColor: '#FF9F45',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok'
    })
  }

  sweetError() {
    swal.fire({
      position: 'center',
      type: 'error',
      icon: 'error',
      title: 'Ops... Algo deu errado',
      text: 'Por favor, tente novamente!',
      showConfirmButton: false,
      timer: 5000,
      showCloseButton: true,
      html: '<p><strong>Por favor, tente novamente!</p></strong><hr><p>Se o erro continuar, entre em contato através do email suporte@ibcgp.org.br</p>'
    })
  }

  sweetWait(msg) {
    swal.fire({
      position: 'center',
      type: 'warning',
      icon: 'warning',
      title: 'AGUARDE',
      text: `${msg}`,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      onBeforeOpen: () => {
        swal.showLoading()
      },
      didOpen: () => {
        swal.showLoading()
      }
    })
  }

  sweetSuccess(msg, timer = 5000) {
    swal.fire({
      timer,
      html: msg,
      type: 'success',
      icon: 'success',
      position: 'center',
      showConfirmButton: false
    })
  }

  sweetSuccessResp(msg) {
    return this.swalWithBootstrapButtons.fire({
      position: 'center',
      type: 'success',
      icon: 'success',
      html: `${msg} <h6>Deseja imprimir a Ficha de Inscrição?</h6>`,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Não',
      reverseButtons: true,
      width: '450px'
    })
  }

  sweetQuestion(msg) {
    return this.swalWithBootstrapButtons.fire({
      html: `${msg} <h6>Deseja imprimir a Ficha de inscrição?</h6>`,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Não',
      type: 'warning',
      icon: 'warning',
      reverseButtons: true,
      width: '450px'
    })
  }

  sweetInput(msg) {
    return this.swalWithBootstrapButtons.fire({
      html: `${msg} <h6>Deseja enviar o seu recurso?</h6>`,
      input: 'text',
      confirmButtonText: 'Enviar',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      type: 'warning',
      icon: 'warning',
      reverseButtons: true,
      width: '450px'
    })
  }

  sweetQuestionPersonalizado(title, html, confirm = 'OK') {
    return this.swalWithBootstrapButtons.fire({
      title: `${title}`,
      confirmButtonText: confirm,
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
      cancelButtonColor: '#999',
      cancelButtonText: 'Voltar',
      type: 'question',
      icon: 'question',
      html: `${html}`,
      reverseButtons: true,
      width: '450px'
    })
  }

  sweetQuestionOptions(html, type = '', confirm = 'Área do Candidato', cancel = 'Nova Inscrição') {
    return this.swalWithBootstrapButtons.fire({
      confirmButtonText: confirm,
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
      cancelButtonColor: '#999',
      cancelButtonText: cancel,
      html: `${html}`,
      width: '500px',
      type: type,
      icon: type,
      allowOutsideClick: true,
      allowEscapeKey: true
    })
  }

  sweetLogin(html, callback) {
    return this.swalWithBootstrapButtons.fire({
      confirmButtonText: 'Validar acesso',
      confirmButtonColor: '#3085d6',
      showCancelButton: false,
      cancelButtonColor: '#999',
      html: `${html}`,
      focusConfirm: false,
      width: '500px',
      type: '',
      icon: '',
      allowOutsideClick: true,
      allowEscapeKey: true,
      preConfirm: callback
    })
  }
}
