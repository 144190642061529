<template>
  <section id="section-print">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="Comprovante de solicitação de Reembolso"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <div slot="pdf-content">
        <div class="row justify-content-bettwen">
          <div class="col-12 align-self-end text-center mb-3">
            <img class="img-brasao" src="../assets/images/brasao.jpeg" />
          </div>
          <div class="col-12 align-self-end">
            <h5 class="text-center font-17">PREFEITURA MUNICIPAL DE VICENTINA</h5>
          </div>
          <div class="col-12 align-self-end mb-4">
            <p class="text-center font-13 weight-600">FORMULÁRIO DE REQUERIMENTO DE DEVOLUÇÃO DO VALOR DA TAXA DEINSCRIÇÃO</p>
          </div>
        </div>

        <div class="row justify-content-center">
          <form @submit.prevent="salvar" class="col-md-6 col-lg-11 text-justify">
            Eu <span class="bold">{{ profile.nome }}</span
            >, portador (a) do RG n.º <span class="bold">{{ profile.rg }}</span
            >, e do CPF n.º <span class="bold">{{ profile.cpf }}</span
            >, inscrito(a) no Processo Seletivo Simplificado nº 01/2024, para o cargo de <span class="bold">{{ profile.cargo }}</span
            >, inscrição nº <span class="bold">{{ pad(profile.id, 6) }}</span
            >, regido pelo Edital de Abertura nº 001/2024, solicito a devolução do valor pago pela taxa de inscrição do referido Processo
            Seletivo Simplificado, em virtude do que consta no Edital nº 03/2024 – Processo Seletivo nº 01/2024, que dispõe sobre a anulação
            das provas aplicadas no dia 04/02/2024 e reaplicação das provas objetivas do Processo Seletivo Simplificado nº 01/2024. Dados
            para Contato: Telefone:
            <span class="bold">{{ profile.telefone_residencial || profile.telefone_celular || profile.telefone_comercial }}</span
            >. E-mail: <span class="bold">{{ profile.email }}</span
            >. DECLARO que estou ciente que o pedido de restituição da taxa de inscrição implica em desistência de minha participação no
            Processo Seletivo Simplificado nº 01/2024, ficando impedida de realização da prova objetiva que será reaplicada no dia
            18/02/2024. <br /><br />

            <div class="row mb-3">
              <p class="weight-600" v-if="option === 1">
                {{ `Opção 01 - Devolução na Conta Bancária do Candidato CPF n.º ${profile.cpf}` }}
              </p>
              <p class="weight-600" v-if="option === 2">{{ 'Opção 02 - Devolução na Conta Bancária de Terceiros:' }}</p>
              <p class="weight-600" v-if="option === 3">{{ 'Opção 03 - Chave PIX:' }}</p>
            </div>

            <div v-if="option !== 3" class="row bg-light py-2">
              <div class="col-md-12">
                <p><span class="weight-600">Banco:</span> {{ form.nomeBanco }}</p>
              </div>
              <div class="col-md-12">
                <p><span class="weight-600">Agência:</span> {{ form.numeroAgencia }}</p>
              </div>
              <div class="col-md-12">
                <p><span class="weight-600">Conta:</span> {{ form.numeroConta }}</p>
              </div>
              <div class="col-md-12">
                <p><span class="weight-600">Dígito:</span> {{ form.digitoConta }}</p>
              </div>
              <div class="col-md-12">
                <p><span class="weight-600">Tipo de Conta:</span> {{ form.tipoConta }}</p>
              </div>
              <div v-if="option === 2" class="col-md-12">
                <p><span class="weight-600">CPF:</span> {{ form.cpf }}</p>
              </div>
            </div>

            <div v-else class="row bg-light py-2">
              <div class="col-md-12">
                <p><span class="weight-600">Chave PIX:</span> {{ form.chavePix }}</p>
              </div>
            </div>

            <div class="text-center mt-5">
              <p class="mb-5">Vicentina/MS, {{ form.createdAt ? mountDate(form.createdAt) : today }} de fevereiro de 2024.</p>
            </div>
          </form>
        </div>
      </div>
    </vue-html2pdf>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'ImpressaoComp',

  components: {
    VueHtml2pdf
  },

  props: {
    form: {},
    option: {}
  },

  computed: {
    ...mapGetters({ profile: 'profile/getProfile' }),

    today() {
      return this.pad(new Date().getDate(), 2)
    }
  },

  methods: {
    mountDate(date) {
      if (!date) return '--'
      return date.slice(8, 10)
    },

    generatePdf() {
      this.$refs.html2Pdf.generatePdf()
    },

    pad(val, num) {
      return `${val}`.padStart(num, '0')
    }
  }
}
</script>
<style lang="sass" scoped>
br
  margin-bottom: 10px
.border-bottom
  border-bottom: 1px solid black
p,h1,h2,h3,h4,h5,h6
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important
p
  font-size: 13px
  color: #000 !important
  font-weight: 300 !important
  span
    font-size: 14px
    font-weight: 600 !important
.position-absolute
  position: absolute
.img-brasao
  width: 80px
.img-imp
  width: 50px
.vue-html2pdf
  .layout-container
    background-color: #fff !important

  .content-wrapper
    background-color: #fff !important
.bg-light
  padding-top: 0px
  padding-bottom: 0px
  i
    font-size: 13px !important
.font-17
  font-size: 17px !important
.font-13
  font-size: 12px !important
.weight-600
  font-weight: 600 !important
</style>
