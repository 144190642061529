<template>
  <div class="area-titulo p-4">
    <div v-if="!isFinished" class="mb-5">
      <form @submit.prevent="enviarArquivo">
        <div class="px-md-5">
          <div class="row justify-content-center bg-light py-3">
            <div class="col-12">
              <h5 class="mb-2">Envie os Títulos</h5>
            </div>
            <div class="col-md-8 mb-3">
              <FieldSelect :options="options" required v-model="arquivo.idTipoArquivo" placeholder="Nome do arquivo" />
            </div>

            <div class="col-md-8 mb-3">
              <FieldFile required accept="application/pdf" keyInput="arquivo" v-model="file" label="Tamanho máximo: 2MB" />
            </div>

            <div class="col-md-5">
              <button type="submit" class="btn btn-primary py-1 font-14">
                <i class="fa-solid fa-plus" />
                Adicionar Título
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <h4 class="mb-2">Lista de Títulos enviados</h4>

    <img v-if="isLoading" src="@/assets/images/loading.gif" class="is-loading" />

    <p v-else-if="!list.length" class="text-secondary text-center weight-400">Nenhum Título enviado</p>

    <div v-else class="px-md-5">
      <div
        :key="item.title"
        v-for="(item, ind) in list"
        :class="['row justify-content-between align-items-center py-2', (ind + 1) % 2 && 'bg-light']"
      >
        <div class="col-md-8 px-2 px-md-3">
          <p class="font-13 text-left w-100 ellipse">
            <span class="pt-1 bold">{{ $f.mountDate(item.created_at) }}</span>
            <span class="pt-1 px-3"></span>
            {{ item.nome }}
          </p>
        </div>
        <div class="col-md-4 text-end px-0 px-md-3 text-start mt-2 mt-md-0">
          <a download target="_blank" :href="item.link" class="btn btn-primary py-1 font-13 text-white mb-1">
            <i class="fa-solid fa-download" />
            Download
          </a>
          <span
            v-if="!isFinished"
            @click="excluir(item)"
            target="_blank"
            :href="item.link"
            class="btn btn-danger py-1 font-13 text-white pointer ml-2 mb-1"
          >
            <i class="fa-solid fa-trash-can" />
            Excluir
          </span>
        </div>
      </div>
    </div>

    <!-- <div class="row justify-content-center mt-4" v-if="!isFinished && !isLoading && list.length">
      <div class="col-md-5">
        <form @submit.prevent="finalizarEnvioTitulos">
          <button type="submit" class="btn btn-success py-1 font-14">
            <i class="fa-regular fa-circle-check" />
            Finalizar entrega de Títulos
          </button>
        </form>
      </div>
    </div> -->

    <div v-if="isFinished && !isLoading">
      <p class="mt-5 text-success weight-400">
        <i class="fa-solid fa-check-double" />
        Títulos entregues.
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import FieldFile from '@/components/fields/FieldFile'
import FieldInput from '@/components/fields/FieldInput'
import FieldSelect from '@/components/fields/FieldSelect'

export default {
  components: {
    FieldFile,
    FieldInput,
    FieldSelect
  },

  data() {
    return {
      list: [],
      isLoading: false,
      isFinish: true,
      arquivo: {
        idTipoArquivo: ''
      },
      file: {},
      options: []
    }
  },

  async created() {
    this.isLoading = true
    await this.loadTitle()
    this.loadArchives()
  },

  methods: {
    async loadTitle() {
      const { data } = await axios.get(`/formulario/${this.$city}/${this.$psCity}/area-candidato/arquivo/tipos-titulo`)
      this.options = data.map((item) => ({ label: item.nome, value: item.id }))
    },

    async excluir(item) {
      const { value } = await this.$sweet.sweetQuestionPersonalizado(
        'Exclusão de título',
        '<p class="font-15">Confirma a exclusão deste título?</p>'
      )
      if (!value) return

      this.$sweet.sweetWait('Excluindo Título.')
      const { error } = await axios
        .delete(`/formulario/${this.$city}/${this.$psCity}/area-candidato/arquivo/${item.id}`)
        .catch(() => ({ error: 'error' }))
      if (error) return this.$sweet.sweetMensagem('Erro ao remover o Título. Tente novamente')
      this.$sweet.sweetSuccess('Título excluído com sucesso.')
      this.loadArchives()
    },

    async loadArchives() {
      this.isLoading = true
      const { data } = await axios.get(`/formulario/${this.$city}/${this.$psCity}/area-candidato/arquivo`)
      this.isLoading = false
      this.list = (data || []).map((i) => ({
        ...i,
        nome: this.options.find(({ value }) => value === i.id_tipo_arquivo || i.idTipoArquivo)?.label || i.nome,
        link: `https://ibcgp-plataforma.sfo3.cdn.digitaloceanspaces.com/concurso-ps/${this.$city}/${this.$ps}/titulos/${this.profile.id}/${i.id}.pdf`
      }))
    },

    async finalizarEnvioTitulos() {
      const { value } = await this.$sweet.sweetQuestionPersonalizado(
        'Finalizar entrega de Títulos',
        '<p class="font-15">Ao efetivar a entrega dos títulos, você não poderá enviar novos Títulos e não poderá excluir os inseridos.</p>'
      )
      if (!value) return

      this.$sweet.sweetWait('Finalizando entrega de Títulos.')
      const { data } = await axios.put(`/formulario/${this.$city}/${this.$psCity}/area-candidato/entrega-titulos-finalizada/S`)
      localStorage.setItem(`token-${this.$city}`, data?.token)
      this.$sweet.sweetSuccess('Entrega de Títulos finalizada.')
      this.$store.dispatch('profile/setProfile', { ...this.profile, entrega_titulos_finalizada: 'S' })
      this.isFinish = true
    },

    async enviarArquivo() {
      this.$sweet.sweetWait('Enviando Título.')

      const { data: idArchive } = await axios
        .post(`/formulario/${this.$city}/${this.$psCity}/area-candidato/arquivo`, this.arquivo)
        .catch((e) => e.response)
      if (isNaN(idArchive)) return this.$sweet.sweetMensagem(idArchive || 'Erro ao Salvar o título')

      const { data: ext, e } = await this.saveDoc(idArchive).catch((e) => ({ e: e.response }))
      if (!ext) return this.$sweet.sweetMensagem(e || 'Erro ao enviar o Título.')

      const { data: putExt, e: err } = await axios
        .put(`/formulario/${this.$city}/${this.$psCity}/area-candidato/arquivo/${idArchive}/extensao/${ext}`)
        .catch((e) => ({ e: e.response }))
      if (!putExt) return this.$sweet.sweetMensagem(err || 'Erro ao atualizar a extensão do Título.')

      this.file = {}
      this.arquivo.idTipoArquivo = ''
      const input = document.getElementById('arquivo-titulos-file')
      if (input) input.value = ''

      this.loadArchives()
      this.$sweet.sweetSuccess('Título cadastrado com sucesso.')
    },

    async saveDoc(id) {
      const headers = { 'Content-Type': 'multipart/form-data' }
      const dataForm = new FormData()
      dataForm.append('arquivo', this.file)
      dataForm.append('diretorio', `concurso-ps/${this.$city}/${this.$ps}/titulos/${this.profile.id}`)
      dataForm.append('identificador', id)

      return axios.post('/arquivo', dataForm, { headers }).catch((err) => err)
    }
  },

  computed: {
    ...mapGetters({ profile: 'profile/getProfile' }),

    isFinished() {
      return this.profile.entrega_titulos_finalizada === 'S' || this.isFinish
    }
  }
}
</script>

<style lang="sass" scoped>
@media (max-width: 500px)
  p
    line-height: 18px
    text-align: left
  a
    margin: auto
    height: 22px
    display: inline-flex
    align-items: center
    justify-content: center
.area-titulo
  background-color: #fff
  padding: 25px 15px
  border-radius: 5px
h4
  font-size: 20px
  text-align: center
  font-weight: 500 !important
h5
  font-size: 18px
  text-align: center
  font-weight: 500 !important
.font-13
  font-size: 13px
.font-15
  font-size: 15px
.font-14
  font-size: 14px
.is-loading
  width: 30px
.weight-400
  font-weight: 400
.vs--single .vs__selected
  text-wrap: nowrap !important
.vs__selected-options
  overflow: hidden !important
.ellipse
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
</style>
