const SET_PROFILE = 'SET_PROFILE'

const state = {
  profile: {}
}

const actions = {
  setProfile ({ commit }, profile) {
    commit(SET_PROFILE, profile)
  },
}

const getters = {
  getProfile: state => state.profile,
}

const mutations = {
  [SET_PROFILE] (state, profile) {
    state.profile = profile
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
