<template>
  <div class="position-relative">
    <label :for="id" v-if="label" class="label" v-html="`${label} ${required ? '*' : ''}`"></label>

    <div class="input-group">
      <input
        :id="id"
        v-if="type !== 'textarea'"
        :required="required"
        :accept="accept"
        :maxlength="maxlength"
        :disabled="isDisabled"
        :class="['form-control', type === 'file' ? 'max-25-px' : '']"
        :type="type"
        v-model="field"
        :placeholder="placeholder"
        @blur="$emit('blur', field)"
        v-mask="mask"
        :onpaste="`return ${onPaste}`"
        :ondrop="`return ${onPaste}`"
      />

      <slot />

      <textarea
        v-if="type === 'textarea'"
        rows="3"
        :id="id"
        :onpaste="`return ${onPaste}`"
        :ondrop="`return ${onPaste}`"
        cols="30"
        v-model="field"
        :required="required"
        :disabled="isDisabled"
        :placeholder="placeholder"
        class="form-control text-area"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    upperCase: {
      type: Boolean,
      default: false
    },
    onPaste: {
      type: Boolean,
      default: true
    },
    maxlength: {
      type: Number,
      default: 800
    },
    accept: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      field: this.value
    }
  },

  watch: {
    value: {
      deep: true,
      handler(value) {
        if (isEqual(value, this.field)) return
        this.field = value
      }
    },

    field: {
      deep: true,
      handler(field) {
        if (isEqual(field, this.value)) return
        const newField = this.upperCase ? field.toUpperCase() : field
        this.$emit('input', newField)
      }
    }
  }
}

const isEqual = (val1, val2) => val1 === val2
</script>

<style>
:root {
  --vs-state-disabled-bg: #e9ecef;
  --vs-state-disabled-color: #082772;
  --vs-state-disabled-controls-color: #082772;
  --vs-state-disabled-cursor: not-allowed;
}
</style>
<style lang="sass">
.form-control.max-25-px
  max-height: 25px !important
  padding-left: 0px !important
</style>
